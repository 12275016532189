import { userRefreshAccessToken } from 'actions';
import { STATUS } from 'constants/index';

let nextActions = [];

export default function createAuthMiddleware() {
  return store => next => action => {
    const { user } = store.getState();

    if (action.type === 'REFRESH_ACCESS_TOKEN_SUCCESS') {
      next(action);

      nextActions.forEach(d => {
        store.dispatch(d);
      });

      nextActions = [];

      return;
    }

    if (
      user.isLogged &&
      user.auth.expires_at < Date.now() &&
      action.type.includes('REQUEST') &&
      !action.type.includes('REFRESH_ACCESS_TOKEN')
    ) {
      nextActions.push(action);

      if (user.refreshToken.status !== STATUS.RUNNING) {
        store.dispatch(userRefreshAccessToken());
      }

      return;
    }

    next(action);
  };
}
