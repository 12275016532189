import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SpotifyPlayer, { TYPE } from 'react-spotify-web-playback';

import { playerUpdate, userRefreshAccessToken, updateTrackStatus } from 'actions';

import Transition from 'components/Transition';

export class Player extends React.PureComponent {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    favorites: PropTypes.array.isRequired,
    player: PropTypes.object.isRequired,
  };

  componentDidUpdate(prevProps) {
    const { favorites, player } = this.props;

    if (prevProps.favorites.length !== favorites.length && this.updateSavedStatus) {
      if (player.uris[player.offset]) {
        const [, , id] = player.uris[player.offset].split(':');

        if (id) {
          this.updateSavedStatus(favorites.some(d => d === id));
        }
      }
    }
  }

  handlePlayerUpdates = ({ type, ...state }) => {
    const { dispatch, favorites, player } = this.props;
    const { errorType, isPlaying, isSaved, track } = state;

    if (isPlaying !== player.isPlaying || track.uri !== player.uri) {
      dispatch(playerUpdate({ isPlaying, uri: track.uri }));
    }

    if (type === TYPE.TRACK && isSaved !== favorites.some(d => d === track.uri)) {
      dispatch(updateTrackStatus({ id: track.id, isSaved }));
    }

    if (errorType === 'authentication_error') {
      dispatch(userRefreshAccessToken());
    }
  };

  render() {
    const { auth, player } = this.props;

    return (
      <Transition classNames="transition-slide-up">
        <div key="Player" className="app__player">
          <SpotifyPlayer
            callback={this.handlePlayerUpdates}
            name="musicbot.io"
            offset={player.offset}
            persistDeviceSelection
            play={player.isPlaying}
            showSaveIcon
            token={auth.access_token}
            updateSavedStatus={c => (this.updateSavedStatus = c)}
            uris={player.uris}
            styles={{
              altColor: '#f04',
              bgColor: '#282828',
              color: '#fff',
              loaderColor: '#007bff',
              sliderColor: '#007bff',
              sliderHeight: 10,
              sliderTrackColor: '#999',
              sliderHandleColor: '#fff',
              trackArtistColor: '#007bff',
              trackNameColor: '#fff',
            }}
          />
        </div>
      </Transition>
    );
  }
}

export default connect(state => ({
  auth: state.user.auth,
  player: state.player,
  favorites: state.spotify.favorites.data,
}))(Player);
