import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Connect from 'containers/Connect';
import Icon from 'components/Icon';
import Logo from 'components/Logo';

const Empty = ({ isLogged }) => {
  let link;
  let icon;

  if (isLogged) {
    link = (
      <Link to={isLogged ? '/generator' : '/'} className="btn btn-lg btn-icon btn-primary">
        <Icon name="plug" />
        <span>{isLogged ? 'Create Playlist' : 'Sign In'}</span>
      </Link>
    );
    icon = <Icon name="broken-note" />;
  } else {
    link = <Connect />;
    icon = <Logo file="logo-vertical" />;
  }

  return (
    <div className="app__empty">
      {icon}
      <h2>Ooops</h2>
      <h4>Nothing to see here</h4>
      {link}
    </div>
  );
};

Empty.propTypes = {
  isLogged: PropTypes.bool.isRequired,
};

export default Empty;
