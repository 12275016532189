declare var window: any;
import { IObject } from 'types/common';

import produce, { Draft } from 'immer';
import { pad } from 'modules/strings';

export function convertRange(value: number, range: number[], scale: number[]): number {
  const next = Math.round(
    ((value - range[0]) * (scale[1] - scale[0])) / (range[1] - range[0]) + scale[0],
  );

  return next > scale[1] ? scale[1] : next;
}

export function getMinutes(milliseconds: number): string {
  if (typeof milliseconds === 'undefined') {
    return '--';
  }

  const time = Math.round(milliseconds / 1000);
  const minutes = Math.floor(time / 60);
  const seconds = time - minutes * 60;

  function str_pad_left(value: number, padding: string = '0', size: number = 2) {
    return (new Array(size + 1).join(padding) + value).slice(-size);
  }

  return `${str_pad_left(minutes)}:${str_pad_left(seconds)}`;
}

export function getPitchClass(key: number) {
  const scale = ['C', 'C#', 'D', 'D♯', 'E', 'F', 'F♯', 'G', 'G♯', 'A', 'A♯', 'B'];

  if (!key || key < 0) {
    return '--';
  }

  return scale[key];
}

export function getTimestamp(hours: number = 1): number {
  const now = Date.now();
  const oneHour = 3600 * 1000;

  return now + oneHour * hours;
}

export function handleActions(actionsMap: IObject, defaultState: any): () => any {
  return (state: any = defaultState, { type, ...rest }: IObject = {}): (() => any) =>
    produce(
      state,
      (draft: any): IObject => {
        const action = actionsMap[type];
        let newState;

        if (action) {
          newState = action(draft, rest);
        }

        if (newState) {
          return newState;
        }

        return draft;
      },
    );
}

export function keyMirror(obj: IObject): IObject {
  const output: IObject = {};

  for (const key in obj) {
    if (output.hasOwnProperty(key)) {
      continue;
    }

    output[key] = key;
  }

  return output;
}

export function logger(type: string, title: string, data: any, options: IObject = {}) {
  const { showTimestamp = true, typeColor = 'gray' } = options;

  if (process.env.NODE_ENV === 'development') {
    const date = new Date();
    const parts = [`%c ${type}`];
    const styles = [`color: ${typeColor}; font-weight: lighter;`, 'color: inherit;'];

    if (showTimestamp) {
      styles.push('color: gray; font-weight: lighter;');
    }

    const time = `${pad(date.getHours(), 2)}:${pad(date.getMinutes(), 2)}:${pad(
      date.getSeconds(),
      2,
    )}`;

    parts.push(`%c${title}`);

    if (showTimestamp) {
      parts.push(`%c@ ${time}`);
    }

    if (!window.SKIP_LOGGER) {
      /* tslint:disable:no-console */
      console.groupCollapsed(parts.join(' '), ...styles);
      console.log(data);
      console.groupEnd();
      /* tslint:enable */
    }
  }
}

// @ts-ignore
export const spread: Draft = produce(Object.assign);
