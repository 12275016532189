import { IPlaylist, IPlaylistTrack, IState } from 'types/common';
import {
  ISpotifyPlayOptions,
  ISpotifyRecommendationsRequest,
  ISpotifyRecommendationsResponse,
  ISpotifyToken,
  ISpotifyTopOptions,
  ISpotifyTrack,
} from 'types/spotify';

import SpotifyWebApi from 'spotify-web-api-node';

import { spotify } from 'config';

const SpotifyApi = new SpotifyWebApi({
  clientId: spotify.clientId,
  redirectUri: spotify.redirectUri,
});

export function parseTrack(track: ISpotifyTrack): IPlaylistTrack {
  return {
    artists: track.artists.map(d => ({ name: d.name, url: d.external_urls.spotify })),
    image: track.album.images[0].url || '',
    name: track.name,
    preview_url: track.preview_url,
    spotify_id: track.id,
    title: `${track.artists[0].name} - ${track.name}`,
    uri: track.uri,
    url: track.external_urls.spotify,
  };
}

export function parseRecommendations(body: ISpotifyRecommendationsResponse) {
  const tracks: IPlaylistTrack[] = [];

  body.tracks.forEach(d => {
    tracks.push(parseTrack(d));
  });

  return {
    seeds: body.seeds,
    tracks,
  };
}

export function getUserData() {
  return SpotifyApi.getMe().then(response => response.body);
}

export function getSavedTracks(payload = { limit: 50, offset: 0 }) {
  return SpotifyApi.getMySavedTracks(payload).then(response => response.body);
}

export function getTopTracks(
  options: ISpotifyTopOptions = { limit: 50, time_range: 'short_term' },
) {
  return SpotifyApi.getMyTopTracks(options).then(response => response.body);
}

export function getTopArtists(
  options: ISpotifyTopOptions = { limit: 50, time_range: 'short_term' },
) {
  return SpotifyApi.getMyTopArtists(options).then(response => response.body);
}

export function getTracks(payload: string[]) {
  return SpotifyApi.getTracks(payload).then(response => response.body);
}

export function createPlaylist(id: string, state: IState) {
  const { playlists, user } = state;
  const playlist = playlists.data.find((d: IPlaylist) => d.uuid === id);
  const { seed_artists, seed_genres, seed_tracks, ...rest } = playlist!.options;
  const description = [];

  if (Array.isArray(seed_tracks) && seed_tracks.length) {
    description.push(`Tracks: ${seed_tracks.map(d => d.name).join(', ')}`);
  }

  if (Array.isArray(seed_artists) && seed_artists.length) {
    description.push(`Artists: ${seed_artists.map(d => d.name).join(', ')}`);
  }

  if (Array.isArray(seed_genres) && seed_genres.length) {
    description.push(`Genres: ${seed_genres.map(d => d.name).join(', ')}`);
  }

  if (rest) {
    const params = Object.keys(rest)
      .map(d => {
        // @ts-ignore
        return `${d}: ${rest[d]}`;
      })
      .join(', ');
    if (params.length) {
      description.push(`Parameters: ${params}`);
    }
  }

  return SpotifyApi.createPlaylist(user.data.id, playlist!.name, {
    description: description.join(' • '),
    public: false,
  }).then(response => ({ id, body: response.body }));
}

export function addTracksToPlaylist(playlist: IPlaylist) {
  return new Promise((resolve, reject) => {
    const tracks = [];

    if (playlist.tracks.length > 50) {
      for (let i = 0; i < playlist.tracks.length; i += 50) {
        tracks.push([...playlist.tracks.map(d => d.uri).slice(i, i + 50)]);
      }
    } else {
      tracks.push([...playlist.tracks.map(d => d.uri)]);
    }

    Promise.all(tracks.map(d => SpotifyApi.addTracksToPlaylist(playlist.spotify_id!, d))).then(
      values => {
        if (values.some(d => d.statusCode !== 201)) {
          reject(values.find(d => d.statusCode !== 201)!.body);
        } else {
          resolve({ id: playlist.uuid });
        }
      },
    );
  });
}

export function getRecommendations(seed: ISpotifyRecommendationsRequest) {
  return SpotifyApi.getRecommendations(seed).then(response => response.body);
}

export function getTracksStatus(payload: string | string[]) {
  return SpotifyApi.containsMySavedTracks(Array.isArray(payload) ? payload : [payload]);
}

export function addToSavedTracks(payload: string) {
  return SpotifyApi.addToMySavedTracks([payload]).then(() => payload);
}

export function removeFromMySavedTracks(payload: string) {
  return SpotifyApi.removeFromMySavedTracks([payload]).then(() => payload);
}

export function getAudioFeatures(payload: string) {
  return SpotifyApi.getAudioFeaturesForTrack(payload).then(response => response.body);
}

export function setTokens(auth: ISpotifyToken) {
  SpotifyApi.setAccessToken(auth.access_token);
  SpotifyApi.setRefreshToken(auth.refresh_token!);
}

export function getDevices() {
  return SpotifyApi.getMyDevices();
}

export function play({ device_id, uris }: ISpotifyPlayOptions) {
  return SpotifyApi.play({ device_id, uris });
}

export default SpotifyApi;
