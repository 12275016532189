import { IObject } from 'types/common';
import { createActions } from 'redux-actions';

import { ActionTypes } from 'constants/index';

export const {
  fetchAudioFeaturesRequest: getAudioFeatures,
  fetchSavedTracksRequest: getSavedTracks,
  fetchTopArtistsRequest: getTopArtists,
  fetchTopTracksRequest: getTopTracks,
  fetchTracksStatusRequest: getTracksStatus,
  removeTrackRequest: removeTrack,
  saveTrackRequest: saveTrack,
  updateTrackStatus,
} = createActions({
  [ActionTypes.FETCH_AUDIO_FEATURES_REQUEST]: (id: string): string => id,
  [ActionTypes.FETCH_SAVED_TRACKS_REQUEST]: (
    payload: IObject = {
      initial: true,
      limit: 50,
      offset: 0,
    },
  ): IObject => payload,
  [ActionTypes.FETCH_TOP_ARTISTS_REQUEST]: (payload: IObject = { limit: 50, offset: 0 }): IObject =>
    payload,
  [ActionTypes.FETCH_TOP_TRACKS_REQUEST]: (payload: IObject = { limit: 50, offset: 0 }): IObject =>
    payload,
  [ActionTypes.FETCH_TRACKS_STATUS_REQUEST]: (payload: string[] = []): IObject => payload,
  [ActionTypes.REMOVE_TRACK_REQUEST]: (payload: string): string => payload,
  [ActionTypes.SAVE_TRACK_REQUEST]: (payload: string): string => payload,
  [ActionTypes.UPDATE_TRACK_STATUS]: (payload: IObject = {}): IObject => payload,
});
