// @flow
import app from './app';
import player from './player';
import playlists from './playlists';
import spotify from './spotify';
import user from './user';

export default {
  ...app,
  ...player,
  ...playlists,
  ...spotify,
  ...user,
};
