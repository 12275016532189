import React from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';

import { appName } from 'config';

const Logo = ({ file }) => (
  <div className="app__logo">
    <SVG src={`${process.env.PUBLIC_URL}/media/brand/${file}.svg`}>
      <img src={`${process.env.PUBLIC_URL}/media/brand/${file}.png`} alt={appName} />
    </SVG>
  </div>
);

Logo.propTypes = {
  file: PropTypes.string,
};

Logo.defaultProps = {
  file: 'logo',
};

export default Logo;
