import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

const ScrollRestoration = ({ location }) => {
  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {};
  }, [location.pathname]);

  return null;
};

ScrollRestoration.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withRouter(ScrollRestoration);
