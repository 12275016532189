import { IObject } from 'types/common';
import { all, call, put, select, take, takeLatest } from 'redux-saga/effects';
import { request } from 'modules/api';
import { getUserData, setTokens } from 'modules/spotify';

import { spotify } from 'config';
import { push } from 'actions';
import { ActionTypes } from 'constants/index';

// tslint:disable:object-literal-sort-keys

/**
 * Sign in.
 */
function* login() {
  try {
    yield put({ type: ActionTypes.FETCH_ACCESS_TOKEN_REQUEST });
    yield take(ActionTypes.FETCH_ACCESS_TOKEN_SUCCESS);

    const { user } = yield select();
    yield call(setTokens, user.auth);

    yield put({ type: ActionTypes.FETCH_USER_DATA_REQUEST });
    yield take(ActionTypes.FETCH_USER_DATA_SUCCESS);

    yield put({ type: ActionTypes.FETCH_PLAYLISTS_REQUEST });
  } catch (err) {
    yield put({ type: ActionTypes.USER_LOGOUT });
  }
}

/**
 * SIgn out.
 */
function* logout({ payload }: IObject) {
  yield put(push(payload.path));
}

/**
 * Get Access Token.
 */
function* fetchAccessToken() {
  const state = yield select();
  try {
    const auth = yield call(request, {
      endpoint: '/token',
      method: 'POST',
      payload: { code: state.user.auth.code, redirect_uri: spotify.redirectUri },
    });

    yield put({
      type: ActionTypes.FETCH_ACCESS_TOKEN_SUCCESS,
      payload: auth,
    });
  } catch (err) {
    yield put({
      type: ActionTypes.FETCH_ACCESS_TOKEN_FAILURE,
      payload: err,
    });
  }
}

/**
 * Refresh Access Token.
 */
function* refreshAccessToken() {
  const { user } = yield select();

  try {
    const auth = yield call(request, {
      endpoint: '/refresh',
      method: 'POST',
      payload: {
        access_token: user.auth.access_token,
        refresh_token: user.auth.refresh_token,
      },
    });

    yield call(setTokens, auth);

    yield put({
      type: ActionTypes.REFRESH_ACCESS_TOKEN_SUCCESS,
      payload: auth,
    });
  } catch (err) {
    yield put({
      type: ActionTypes.REFRESH_ACCESS_TOKEN_FAILURE,
    });
  }
}

/**
 * Get user data
 */
function* fetchUserData() {
  try {
    const data = yield call(getUserData);

    yield put({
      type: ActionTypes.FETCH_USER_DATA_SUCCESS,
      payload: data,
    });
    yield put(push('/generator'));
  } catch (err) {
    yield put({
      type: ActionTypes.FETCH_USER_DATA_FAILURE,
      payload: err,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.FETCH_ACCESS_TOKEN_REQUEST, fetchAccessToken),
    takeLatest(ActionTypes.FETCH_USER_DATA_REQUEST, fetchUserData),
    takeLatest(ActionTypes.REFRESH_ACCESS_TOKEN_REQUEST, refreshAccessToken),
    takeLatest(ActionTypes.USER_LOGIN, login),
    takeLatest(ActionTypes.USER_LOGOUT, logout),
  ]);
}
