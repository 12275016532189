import { applyMiddleware, createStore, compose, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { connectRouter } from 'simple-react-router-redux';

import rootSaga from 'sagas';
import rootReducer from 'reducers';

import history from 'store/custom-history';

import middleware, { sagaMiddleware } from './middleware';

const reducer = persistReducer(
  {
    key: 'musicbot',
    storage,
    blacklist: ['player'],
  },
  combineReducers({
    router: connectRouter(history),
    ...rootReducer,
  }),
);

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

/* istanbul ignore next */
const configStore = (initialState = {}) => {
  const store = createStore(reducer, initialState, composeEnhancer(applyMiddleware(...middleware)));

  sagaMiddleware.run(rootSaga);

  if (module.hot) {
    module.hot.accept('reducers', () => {
      store.replaceReducer(require('reducers').default);
    });
  }

  return {
    persistor: persistStore(store),
    store,
  };
};

const { store, persistor } = configStore();

global.store = store;

export { store, persistor };
