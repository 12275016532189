import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const Loader = ({ debounce, grow, type }) => {
  const [loaded, setLoaded] = useState(false);
  let html;

  useEffect(() => {
    let timeout;

    if (debounce) {
      timeout = setTimeout(() => {
        setLoaded(true);
      }, debounce);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [debounce]);

  if (!debounce || (debounce && loaded)) {
    if (type === 'pulse') {
      html = (
        <div className="app__loader__pulse">
          <div />
        </div>
      );
    } else if (type === 'bounce') {
      html = (
        <div className="app__loader__bounce">
          <div />
          <div />
        </div>
      );
    } else {
      html = (
        <div className="app__loader__rotate">
          <svg className="loader__svg">
            <circle className="loader__circle" cx="50" cy="50" r="20" fill="none" strokeWidth="2" />
          </svg>
        </div>
      );
    }
  }

  return (
    <div
      className={cx('app__loader', {
        'app__loader--grow': grow,
      })}
    >
      {html}
    </div>
  );
};

Loader.propTypes = {
  debounce: PropTypes.number,
  grow: PropTypes.bool,
  type: PropTypes.string,
};

Loader.defaultProps = {
  debounce: 200,
  type: 'pulse',
};

export default Loader;
