import { IObject } from 'types/common';
import { IAppState } from 'types/state';

import { handleActions, spread } from 'modules/helpers';

import { ActionTypes, REHYDRATE } from 'constants/index';
import { storageVersion } from 'config';

export const appState: IAppState = {
  generator: {
    isPristine: true,
    limit: 50,
    max_acousticness: 1,
    max_danceability: 1,
    max_energy: 1,
    max_instrumentalness: 1,
    max_liveness: 1,
    max_popularity: 100,
    max_speechiness: 1,
    max_tempo: 150,
    max_valence: 1,
    min_acousticness: 0,
    min_danceability: 0,
    min_energy: 0,
    min_instrumentalness: 0,
    min_liveness: 0,
    min_popularity: 0,
    min_speechiness: 0,
    min_tempo: 70,
    min_valence: 0,
    seed_artists: [],
    seed_genres: [],
    seed_tracks: [],
    target_acousticness: 0,
    target_danceability: 0,
    target_energy: 0,
    target_instrumentalness: 0,
    target_liveness: 0,
    target_popularity: 0,
    target_speechiness: 0,
    target_tempo: 70,
    target_valence: 0,
  },
  notifications: {
    message: '',
    type: '',
    visible: false,
    withTimeout: true,
  },
  storageVersion: storageVersion,
};

export default {
  app: handleActions(
    {
      [REHYDRATE]: (draft: IObject) => {
        draft.notifications = appState.notifications;
      },
      [ActionTypes.ADD_TO_GENERATOR]: (draft: IObject, { payload }: IObject) => {
        const generator = {
          ...draft.generator,
          isPristine: false,
        };
        generator[payload.index] = [payload.data, ...generator[payload.index]].slice(0, 5);

        draft.generator = generator;
      },
      [ActionTypes.SAVE_GENERATOR_OPTIONS]: (draft: IObject, { payload }: IObject) => {
        draft.generator = spread(draft.generator, { isPristine: false }, payload);
      },
      [ActionTypes.RESET_GENERATOR]: (draft: IObject) => {
        draft.generator = appState.generator;
      },
      [ActionTypes.HIDE_ALERT]: (draft: IObject) => {
        draft.notifications.visible = false;
      },
      [ActionTypes.SHOW_ALERT]: (draft: IObject, action: IObject) => {
        draft.notifications.visible = true;
        draft.notifications.type = action.payload.type;
        draft.notifications.message = action.payload.message;
        draft.notifications.withTimeout = !!action.payload.withTimeout;
      },
      [ActionTypes.USER_LOGOUT]: (): IObject => appState,
      [ActionTypes.GENERATE_PLAYLIST_SUCCESS]: (draft: IObject) => {
        draft.generator = appState.generator;
      },
    },
    appState,
  ),
};
