import { IObject } from 'types/common';
import { createActions } from 'redux-actions';

import { ActionTypes } from 'constants/index';

export const {
  addToGenerator,
  hideAlert,
  resetGenerator,
  saveGeneratorOptions,
  showAlert,
} = createActions({
  [ActionTypes.ADD_TO_GENERATOR]: (payload: IObject): IObject => payload,
  [ActionTypes.HIDE_ALERT]: (): IObject => ({}),
  [ActionTypes.RESET_GENERATOR]: (): IObject => ({}),
  [ActionTypes.SAVE_GENERATOR_OPTIONS]: (payload: IObject): IObject => payload,

  /**
   * Show a message.
   *
   * @function
   * @param {string} message
   * @param {string} type - Message type: success, warning, error, info.
   * @param {boolean} withTimeout - Should close automatically.
   *
   * @returns {Object}
   */
  [ActionTypes.SHOW_ALERT]: (
    message: string,
    type: string,
    withTimeout: boolean = true,
  ): IObject => ({
    message,
    type,
    withTimeout,
  }),
});
