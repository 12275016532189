import { IObject } from 'types/common';
import { createActions } from 'redux-actions';

import { ActionTypes } from 'constants/index';

export const {
  userLogin,
  userLogout,
  refreshAccessTokenRequest: userRefreshAccessToken,
} = createActions({
  [ActionTypes.USER_LOGIN]: (payload: IObject): IObject => payload,
  [ActionTypes.USER_LOGOUT]: (path: string = '/'): IObject => ({ path }),
  [ActionTypes.REFRESH_ACCESS_TOKEN_REQUEST]: (): IObject => ({}),
});
