import { IObject } from 'types/common';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { request } from 'modules/api';

import { ActionTypes } from 'constants/index';
import { push, showAlert } from 'actions';

import {
  addTracksToPlaylist,
  createPlaylist,
  getRecommendations,
  getTracks,
} from 'modules/spotify';

// tslint:disable:object-literal-sort-keys

function* exportPlaylistTracks({ payload }: IObject) {
  const { playlists } = yield select();
  const playlist = playlists.data.find((d: IObject) => d.uuid === payload);

  if (!playlist) {
    yield put(showAlert('Export Playlist not found', 'error'));
  } else {
    try {
      const body = yield call(addTracksToPlaylist, playlist);
      yield put({
        type: ActionTypes.EXPORT_PLAYLIST_SUCCESS,
        payload: body,
      });
    } catch (err) {
      yield put({
        type: ActionTypes.EXPORT_PLAYLIST_FAILURE,
        payload: err,
      });
    }
  }
}

function* exportPlaylist({ payload }: IObject) {
  const state = yield select();

  try {
    const data = yield call(createPlaylist, payload, state);
    yield put({
      type: ActionTypes.EXPORT_PLAYLIST_CREATED,
      payload: { id: payload, body: data.body },
    });

    yield call(exportPlaylistTracks, { payload });
    yield put(showAlert('Playlist exported successfully to your Spotify account.', 'success'));

    const playlist = state.playlists.data.find((d: IObject) => d.uuid === payload);
    yield put({
      type: ActionTypes.UPDATE_PLAYLIST_REQUEST,
      payload: {
        id: payload,
        data: { exported: true, spotify_id: playlist.spotify_id, url: playlist.url },
      },
    });
  } catch (err) {
    yield put({
      type: ActionTypes.EXPORT_PLAYLIST_CREATED_FAILURE,
      payload: err,
    });
    yield put(showAlert('Export failed, try again later.', 'error', true));
  }
}

function* fetchPlaylists() {
  const state = yield select();

  try {
    const playlists = yield call(request, {
      endpoint: '/playlists',
      headers: { 'X-Authorization': state.user.auth.access_token },
    });

    yield put({
      type: ActionTypes.FETCH_PLAYLISTS_SUCCESS,
      payload: playlists,
    });
  } catch (error) {
    yield put({ type: ActionTypes.FETCH_PLAYLISTS_FAILURE, payload: error });
  }
}

function* generatePlaylist({ payload }: IObject) {
  const { user } = yield select();
  const defaultLimit = 100 - payload.options.seed_tracks.length;
  const seeds = {
    ...payload.seeds,
    limit: payload.seeds.limit || defaultLimit,
    market: user.data.country,
  };

  try {
    const recommendations = yield call(getRecommendations, seeds);
    let baseTracks = { tracks: [] };

    if (payload.options.seed_tracks.length) {
      baseTracks = yield call(
        getTracks,
        payload.options.seed_tracks.map((d: IObject): string => d.id),
      );
    }

    yield put({
      type: ActionTypes.GENERATE_PLAYLIST_SUCCESS,
      payload: {
        tracks: [...baseTracks.tracks, ...recommendations.tracks],
      },
    });

    const { playlists } = yield select();
    yield put(push(`/playlists/${payload.uuid}`));

    yield put({
      type: ActionTypes.SAVE_PLAYLIST_REQUEST,
      payload: {
        playlist: playlists.data.find((d: IObject) => d.uuid === payload.uuid),
      },
    });
  } catch (err) {
    yield put({
      type: ActionTypes.GENERATE_PLAYLIST_FAILURE,
      payload: err,
    });
  }
}

function* removePlaylist({ payload }: IObject) {
  const { user } = yield select();

  try {
    yield call(request, {
      endpoint: `/playlist/${payload}`,
      headers: {
        'X-Authorization': user.auth.access_token,
      },
      method: 'DELETE',
    });
    yield put(push('/playlists'));

    yield put({
      type: ActionTypes.REMOVE_PLAYLIST_SUCCESS,
      payload: { id: payload },
    });
  } catch (err) {
    yield put({
      type: ActionTypes.REMOVE_PLAYLIST_FAILURE,
      payload: err,
    });
    yield put(showAlert(err, 'error'));
  }
}

function* removeTracks({ payload }: IObject) {
  const { user } = yield select();
  try {
    yield call(request, {
      endpoint: `/playlist/${payload.playlistId}/${payload.spotifyId}`,
      headers: {
        'X-Authorization': user.auth.access_token,
      },
      method: 'DELETE',
    });

    yield put({
      type: ActionTypes.REMOVE_FROM_PLAYLIST_SUCCESS,
      payload,
    });
  } catch (err) {
    yield put({
      type: ActionTypes.REMOVE_FROM_PLAYLIST_FAILURE,
      payload: err,
    });
  }
}

function* savePlaylist({ payload }: IObject) {
  const { user } = yield select();

  try {
    const response = yield call(request, {
      endpoint: '/playlist',
      headers: {
        'X-Authorization': user.auth.access_token,
      },
      method: 'POST',
      payload: { ...payload.playlist, user_id: user.data.id },
    });

    yield put({
      type: ActionTypes.SAVE_PLAYLIST_SUCCESS,
      payload: response,
    });
  } catch (err) {
    yield put({
      type: ActionTypes.SAVE_PLAYLIST_FAILURE,
      payload: err,
    });
  }
}

function* updatePlaylist({ payload }: IObject) {
  const { user } = yield select();
  try {
    const response = yield call(request, {
      endpoint: `/playlist/${payload.id}`,
      headers: {
        'X-Authorization': user.auth.access_token,
      },
      method: 'PUT',
      payload: payload.data,
    });

    yield put({
      type: ActionTypes.UPDATE_PLAYLIST_SUCCESS,
      payload: response,
    });
  } catch (err) {
    yield put({
      type: ActionTypes.UPDATE_PLAYLIST_FAILURE,
      payload: err,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.EXPORT_PLAYLIST_REQUEST, exportPlaylist),
    takeLatest(ActionTypes.FETCH_PLAYLISTS_REQUEST, fetchPlaylists),
    takeLatest(ActionTypes.GENERATE_PLAYLIST_REQUEST, generatePlaylist),
    takeLatest(ActionTypes.REMOVE_FROM_PLAYLIST_REQUEST, removeTracks),
    takeLatest(ActionTypes.REMOVE_PLAYLIST_REQUEST, removePlaylist),
    takeLatest(ActionTypes.SAVE_PLAYLIST_REQUEST, savePlaylist),
    takeLatest(ActionTypes.UPDATE_PLAYLIST_REQUEST, updatePlaylist),
  ]);
}
