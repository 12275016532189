import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { pageView } from 'modules/analytics';

import { userLogin } from 'actions';

import Loader from 'components/Loader';

const Callback = ({ dispatch, location }) => {
  useEffect(() => {
    dispatch(userLogin(location.query));
    pageView('Callback');
  }, [dispatch, location]);

  return (
    <div className="app__callback">
      <Loader grow={true} type="bounce" />
    </div>
  );
};

Callback.propTypes = {
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default Callback;
