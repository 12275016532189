import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// @ts-ignore
import { PersistGate } from 'redux-persist/lib/integration/react';

import { store, persistor } from 'store';

import App from 'containers/App';
import Loader from 'components/Loader';

import '../styles/main.scss';

export class AppStart {
  private cssRetries: number;
  private fetchRetries: number;
  private retryCSS?: () => any;

  constructor() {
    this.cssRetries = 0;
    this.fetchRetries = 0;
  }

  public run() {
    /* istanbul ignore next */
    if (process.env.NODE_ENV === 'production') {
      const offlineRuntime = require('offline-plugin/runtime');
      offlineRuntime.install({
        onUpdateReady: () => {
          // tslint:disable-next-line:no-console
          console.log('SW Event:', 'Applying update');
          offlineRuntime.applyUpdate();
        },
      });
    }

    if (process.env.NODE_ENV === 'development') {
      this.render(App);
      return;
    }

    return this.loadCSS()
      .then(() => this.render(App))
      .catch(reason => {
        if (this.fetchRetries < 3) {
          this.fetchRetries++;
          this.run();
        }
        // tslint:disable-next-line:no-console
        console.log(reason);
      });
  }

  private loadCSS() {
    return new Promise(resolve => {
      this.retryCSS = () => {
        if (this.isCSSLoaded() || this.cssRetries > 2) {
          resolve();
        } else {
          this.cssRetries++;
          setTimeout(() => {
            this.retryCSS!();
          }, this.cssRetries * 500);
        }
      };

      this.retryCSS();
    });
  }

  private isCSSLoaded() {
    const styles = document.styleSheets;

    try {
      // TODO 2019-06-29 gilbarbara: fix this
      // @ts-ignore
      for (const style of styles) {
        if (style.href && style.href.match('app..*.css')) {
          if (style.cssRules !== null && style.cssRules.length > 0) {
            return true;
          }
        }
      }
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.log(error);
    }

    return false;
  }

  private render(Component: React.ElementType) {
    const target = document.getElementById('react');

    /* istanbul ignore next */
    if (target) {
      ReactDOM.render(
        <Provider store={store}>
          <PersistGate loading={<Loader />} persistor={persistor}>
            <Component />
          </PersistGate>
        </Provider>,
        target,
      );
    }
  }
}

const app = new AppStart();

app.run();
