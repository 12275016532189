import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { event } from 'modules/analytics';
import SpotifyApi from 'modules/spotify';

import { spotify } from 'config';

import Icon from 'components/Icon';
import Loader from 'components/Loader';

export class Connect extends React.PureComponent {
  static propTypes = {
    size: PropTypes.string,
    user: PropTypes.object.isRequired,
  };

  static defaultProps = {
    size: 'lg',
  };

  handleClickButton = () => {
    event('connect', 'click');
  };

  render() {
    const { size, user } = this.props;
    const authUrl = SpotifyApi.createAuthorizeURL(spotify.scopes, 'auth');
    let html = <Loader />;

    if (!user.isLogged && !user.isLogged) {
      html = (
        <a
          href={authUrl}
          className={`btn btn-${size} btn-primary btn-icon`}
          onClick={this.handleClickButton}
        >
          <Icon name="spotify" />
          <span>Connect</span>
        </a>
      );
    }

    return <div className="app__connect">{html}</div>;
  }
}

function mapStateToProps(state) {
  return { user: state.user };
}

export default connect(mapStateToProps)(Connect);
