import { logger } from './helpers';

import { googleAnalyticsID } from 'config';

declare let gtag: any;

/**
 * Send a page view to analytics.
 */
export function pageView(
  title: string = document.title,
  uri: string = `${window.location.pathname}${window.location.search}`,
) {
  if (typeof gtag !== 'undefined') {
    gtag('config', googleAnalyticsID, {
      page_path: uri,
      page_title: title,
    });
  } else {
    // tslint:disable-next-line:no-console
    console.log('pageview', title, uri);
  }

  logger('pageview', title, arguments, { typeColor: '#007bff' });
}

/**
 * Send an event to analytics.
 */
export function event(
  category: string,
  action: string = 'click',
  label?: string,
  value: number = 1,
) {
  if (!category) {
    throw new Error('Missing category');
  }

  if (typeof gtag !== 'undefined') {
    gtag('event', action, {
      event_category: category,
      event_label: label,
      value,
    });
  } else {
    // tslint:disable-next-line:no-console
    console.log('event', category, action, label, value);
  }

  logger('event', `${category}/${action}`, arguments, { typeColor: '#f04' });
}
