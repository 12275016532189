import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { pageView } from 'modules/analytics';

import Empty from 'components/Empty';

const NotFound = ({ user }) => {
  React.useEffect(() => {
    pageView(`Not Found — ${user.isLogged ? 'Logged' : 'Anonymous'}`);
  }, [user]);

  return (
    <div key="404" className="app__not-found">
      <Empty isLogged={user.isLogged} />
    </div>
  );
};

NotFound.propTypes = {
  user: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(NotFound);
