import { createBrowserHistory } from 'history';
import { parse } from 'query-string';

const history = createBrowserHistory();

history.location = {
  ...history.location,
  query: parse(history.location.search),
  state: {},
};

/* istanbul ignore next */
history.listen(() => {
  history.location = {
    ...history.location,
    query: parse(history.location.search),
    state: history.location.state || {},
  };
});

export default history;
