import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { NavLink, Link } from 'react-router-dom';
import { event } from 'modules/analytics';

import { push, userLogout } from 'actions';

import Connect from 'containers/Connect';
import Icon from 'components/Icon';
import Logo from 'components/Logo';

const Header = ({ dispatch, user }) => {
  const output = {};

  const handleClickUser = useCallback(
    e => {
      e.preventDefault();
      const el = e.currentTarget;

      if (global.innerWidth < 480) {
        el.classList.toggle('expand');
      } else if (window.location.pathname !== '/library') {
        dispatch(push('/library'));
      }
    },
    [dispatch],
  );

  const handleClickLogout = useCallback(
    e => {
      e.preventDefault();

      event('global', 'logout');
      dispatch(userLogout());
    },
    [dispatch],
  );

  if (user.isLogged) {
    if (user.data.display_name) {
      output.user = (
        <div className="app__header__user">
          <button type="button" onClick={handleClickUser}>
            <img src={user.data.images[0].url} alt={user.data.display_name} />
          </button>
          <div>
            <Link to="/library">{user.data.display_name}</Link>
            <button type="button" className="app__header__logout" onClick={handleClickLogout}>
              <span>logout</span>
              <Icon name="sign-out" />
            </button>
          </div>
        </div>
      );
    }

    output.menu = (
      <div className="app__header__menu">
        <ul className="list-unstyled">
          <li>
            <NavLink exact to="/generator" activeClassName="active">
              <Icon name="plug" />
              <span>Generator</span>
            </NavLink>
          </li>
          <li>
            <NavLink exact to="/playlists" activeClassName="active">
              <Icon name="playlist" />
              <span>Playlists</span>
            </NavLink>
          </li>
          <li>
            <NavLink exact to="/library" activeClassName="active">
              <Icon name="library" />
              <span>Your Library</span>
            </NavLink>
          </li>
        </ul>
      </div>
    );
  } else {
    output.user = <Connect size="sm" />;
  }

  return (
    <header className="app__header">
      <div className="app__container">
        <Link to="/generator" className="app__header__logo">
          <Logo />
        </Link>
        {output.menu}
        {output.user}
      </div>
    </header>
  );
};

Header.propTypes = {
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

export default Header;
