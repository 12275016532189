import { IObject } from 'types/common';
import { ISpotifyState } from 'types/state';
import blacklist from 'blacklist';

import { handleActions } from 'modules/helpers';

import { ActionTypes, REHYDRATE, STATUS } from 'constants/index';

function mergeFavorites(favorites: string[], data: string[], remove?: boolean): string[] {
  let merged = [...favorites];

  data.forEach((id: string) => {
    const exists = merged.find((d: string): boolean => d === id);

    if (remove) {
      merged = merged.filter((d: string): boolean => d !== id);
    } else if (!exists) {
      merged.push(id);
    }
  });

  return merged;
}

export const spotifyState: ISpotifyState = {
  audioFeatures: [],
  favorites: {
    data: [],
    error: '',
    status: STATUS.IDLE,
  },
  library: {
    data: [],
    error: '',
    expires_at: 0,
    status: STATUS.IDLE,
    total: 0,
  },
  removeTracks: [],
  saveTracks: [],
  topArtists: {
    data: [],
    error: '',
    expires_at: 0,
    status: STATUS.IDLE,
    total: 0,
  },
  topTracks: {
    data: [],
    error: '',
    expires_at: 0,
    status: STATUS.IDLE,
    total: 0,
  },
};

export default {
  spotify: handleActions(
    {
      [REHYDRATE]: (draft: IObject, { payload }: IObject): IObject => {
        const spotify = payload && payload.spotify ? { ...draft, ...payload.spotify } : draft;

        spotify.favorites.status = STATUS.IDLE;
        spotify.library.status = STATUS.IDLE;
        spotify.topArtists.status = STATUS.IDLE;
        spotify.topTracks.status = STATUS.IDLE;
        spotify.removeTracks = [];
        spotify.saveTracks = [];

        return spotify;
      },
      // FETCH_AUDIO_FEATURES
      [ActionTypes.FETCH_AUDIO_FEATURES_REQUEST]: (draft: IObject, { payload }: IObject) => {
        draft.audioFeatures.push({
          error: '',
          id: payload,
          status: STATUS.RUNNING,
        });
      },
      [ActionTypes.FETCH_AUDIO_FEATURES_SUCCESS]: (draft: IObject, { payload }: IObject) => {
        const attributes = blacklist(payload, 'analysis_url', 'track_href', 'type', 'uri');

        draft.audioFeatures = draft.audioFeatures.map(
          (d: IObject): IObject =>
            d.id !== payload.id
              ? d
              : {
                  ...d,
                  ...attributes,
                  status: STATUS.SUCCESS,
                },
        );
      },
      [ActionTypes.FETCH_AUDIO_FEATURES_FAILURE]: (draft: IObject, { payload }: IObject) => {
        draft.audioFeatures = draft.audioFeatures.map(
          (d: IObject): IObject =>
            d.id !== payload.id
              ? d
              : {
                  ...d,
                  error: payload.error,
                  status: STATUS.ERROR,
                },
        );
      },
      [ActionTypes.RESET_AUDIO_FEATURES]: (draft: IObject) => {
        draft.audioFeatures = [];
      },
      // FETCH_SAVED_TRACKS
      [ActionTypes.FETCH_SAVED_TRACKS_REQUEST]: (draft: IObject, { payload }: IObject) => {
        draft.library.data = payload.initial ? [] : draft.library.data;
        draft.library.error = '';
        draft.library.status = STATUS.RUNNING;
      },
      [ActionTypes.FETCH_SAVED_TRACKS_SUCCESS]: (draft: IObject, { meta, payload }: IObject) => {
        draft.library.data = [...draft.library.data, ...payload.items];
        draft.library.status = STATUS.SUCCESS;
        draft.library.total = payload.total;
        draft.library.expires_at = meta.expires_at;

        draft.favorites.data = mergeFavorites(
          draft.favorites.data,
          payload.items.map((d: IObject): string => d.track.id),
        );
      },
      [ActionTypes.FETCH_SAVED_TRACKS_FAILURE]: (draft: IObject, { payload }: IObject) => {
        draft.library.error = payload;
        draft.library.status = STATUS.ERROR;
      },
      // FETCH_TOP_ARTISTS
      [ActionTypes.FETCH_TOP_ARTISTS_REQUEST]: (draft: IObject) => {
        draft.topArtists.error = '';
        draft.topArtists.status = STATUS.RUNNING;
      },
      [ActionTypes.FETCH_TOP_ARTISTS_SUCCESS]: (draft: IObject, { meta, payload }: IObject) => {
        draft.topArtists.data = payload.items;
        draft.topArtists.status = STATUS.SUCCESS;
        draft.topArtists.total = payload.total;
        draft.topArtists.expires_at = meta.expires_at;
      },
      [ActionTypes.FETCH_TOP_ARTISTS_FAILURE]: (draft: IObject, { payload }: IObject) => {
        draft.topArtists.error = payload;
        draft.topArtists.status = STATUS.ERROR;
      },
      // FETCH_TOP_TRACKS
      [ActionTypes.FETCH_TOP_TRACKS_REQUEST]: (draft: IObject) => {
        draft.topTracks.error = '';
        draft.topTracks.status = STATUS.RUNNING;
      },
      [ActionTypes.FETCH_TOP_TRACKS_SUCCESS]: (draft: IObject, { meta, payload }: IObject) => {
        draft.topTracks.data = payload.items;
        draft.topTracks.status = STATUS.SUCCESS;
        draft.topTracks.total = payload.total;
        draft.topTracks.expires_at = meta.expires_at;

        draft.favorites.data = mergeFavorites(
          draft.favorites.data,
          payload.items.map((d: IObject): string => d.id),
        );
      },
      [ActionTypes.FETCH_TOP_TRACKS_FAILURE]: (draft: IObject, { payload }: IObject) => {
        draft.topArtists.error = payload;
        draft.topArtists.status = STATUS.ERROR;
      },
      // FETCH_TRACKS_STATUS
      [ActionTypes.FETCH_TRACKS_STATUS_REQUEST]: (draft: IObject) => {
        draft.favorites.error = '';
        draft.favorites.status = STATUS.RUNNING;
      },
      [ActionTypes.FETCH_TRACKS_STATUS_SUCCESS]: (draft: IObject, { payload }: IObject) => {
        draft.favorites.data = mergeFavorites(draft.favorites.data, payload);
        draft.favorites.status = STATUS.SUCCESS;
      },
      [ActionTypes.FETCH_TRACKS_STATUS_FAILURE]: (draft: IObject, { payload }: IObject) => {
        draft.favorites.error = payload;
        draft.favorites.status = STATUS.ERROR;
      },
      // SAVE_TRACK
      [ActionTypes.SAVE_TRACK_REQUEST]: (draft: IObject, { payload }: IObject) => {
        draft.saveTracks.push({
          error: '',
          id: payload,
          status: STATUS.RUNNING,
        });
      },
      [ActionTypes.SAVE_TRACK_SUCCESS]: (draft: IObject, { payload }: IObject) => {
        draft.saveTracks = draft.saveTracks.map(
          (d: IObject): IObject =>
            d.id === payload
              ? {
                  ...d,
                  status: STATUS.SUCCESS,
                }
              : d,
        );
        draft.favorites.data = mergeFavorites(draft.favorites.data, [payload]);
      },
      [ActionTypes.SAVE_TRACK_FAILURE]: (draft: IObject, { error, payload }: IObject) => {
        draft.saveTracks = draft.saveTracks.map(
          (d: IObject): IObject =>
            d.id === payload
              ? {
                  ...d,
                  error,
                  status: STATUS.ERROR,
                }
              : d,
        );
      },
      // REMOVE_TRACK
      [ActionTypes.REMOVE_TRACK_REQUEST]: (draft: IObject, { payload }: IObject) => {
        draft.removeTracks.push({
          error: '',
          id: payload,
          status: STATUS.RUNNING,
        });
      },
      [ActionTypes.REMOVE_TRACK_SUCCESS]: (draft: IObject, { payload }: IObject) => {
        draft.removeTracks = draft.removeTracks.map(
          (d: IObject): IObject =>
            d.id === payload
              ? {
                  ...d,
                  status: STATUS.SUCCESS,
                }
              : d,
        );
        draft.favorites.data = mergeFavorites(draft.favorites.data, [payload], true);
      },
      [ActionTypes.REMOVE_TRACK_FAILURE]: (draft: IObject, { error, payload }: IObject) => {
        draft.removeTracks = draft.removeTracks.map(
          (d: IObject): IObject =>
            d.id === payload
              ? {
                  ...d,
                  error,
                  status: STATUS.ERROR,
                }
              : d,
        );
      },
      [ActionTypes.UPDATE_TRACK_STATUS]: (draft: IObject, { payload }: IObject) => {
        draft.favorites.data = mergeFavorites(draft.favorites.data, [payload.id], !payload.isSaved);
      },
      [ActionTypes.USER_LOGOUT]: (): IObject => spotifyState,
    },
    spotifyState,
  ),
};
