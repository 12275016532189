// @ts-ignore
export { REHYDRATE } from 'redux-persist/lib/constants';
import { keyMirror } from 'modules/helpers';

// tslint:disable:object-literal-sort-keys
export const ActionTypes = keyMirror({
  // USER AUTH
  USER_LOGIN: undefined,
  USER_LOGOUT: undefined,
  FETCH_ACCESS_TOKEN_REQUEST: undefined,
  FETCH_ACCESS_TOKEN_SUCCESS: undefined,
  FETCH_ACCESS_TOKEN_FAILURE: undefined,
  REFRESH_ACCESS_TOKEN_REQUEST: undefined,
  REFRESH_ACCESS_TOKEN_SUCCESS: undefined,
  REFRESH_ACCESS_TOKEN_FAILURE: undefined,
  // USER DATA
  FETCH_USER_DATA_REQUEST: undefined,
  FETCH_USER_DATA_SUCCESS: undefined,
  FETCH_USER_DATA_FAILURE: undefined,
  FETCH_SAVED_TRACKS_REQUEST: undefined,
  FETCH_SAVED_TRACKS_SUCCESS: undefined,
  FETCH_SAVED_TRACKS_FAILURE: undefined,
  FETCH_TOP_TRACKS_REQUEST: undefined,
  FETCH_TOP_TRACKS_SUCCESS: undefined,
  FETCH_TOP_TRACKS_FAILURE: undefined,
  FETCH_TOP_ARTISTS_REQUEST: undefined,
  FETCH_TOP_ARTISTS_SUCCESS: undefined,
  FETCH_TOP_ARTISTS_FAILURE: undefined,
  // TRACKS
  FETCH_AUDIO_FEATURES_REQUEST: undefined,
  FETCH_AUDIO_FEATURES_SUCCESS: undefined,
  FETCH_AUDIO_FEATURES_FAILURE: undefined,
  RESET_AUDIO_FEATURES: undefined,
  FETCH_TRACKS_STATUS_REQUEST: undefined,
  FETCH_TRACKS_STATUS_SUCCESS: undefined,
  FETCH_TRACKS_STATUS_FAILURE: undefined,
  SAVE_TRACK_REQUEST: undefined,
  SAVE_TRACK_SUCCESS: undefined,
  SAVE_TRACK_FAILURE: undefined,
  REMOVE_TRACK_REQUEST: undefined,
  REMOVE_TRACK_SUCCESS: undefined,
  REMOVE_TRACK_FAILURE: undefined,
  UPDATE_TRACK_STATUS: undefined,
  // GENERATOR
  GENERATE_PLAYLIST_REQUEST: undefined,
  GENERATE_PLAYLIST_SUCCESS: undefined,
  GENERATE_PLAYLIST_FAILURE: undefined,
  ADD_TO_GENERATOR: undefined,
  SAVE_GENERATOR_OPTIONS: undefined,
  RESET_GENERATOR: undefined,
  // PLAYLISTS
  SAVE_PLAYLIST_REQUEST: undefined,
  SAVE_PLAYLIST_SUCCESS: undefined,
  SAVE_PLAYLIST_FAILURE: undefined,
  UPDATE_PLAYLIST_REQUEST: undefined,
  UPDATE_PLAYLIST_SUCCESS: undefined,
  UPDATE_PLAYLIST_FAILURE: undefined,
  FETCH_PLAYLISTS_REQUEST: undefined,
  FETCH_PLAYLISTS_SUCCESS: undefined,
  FETCH_PLAYLISTS_FAILURE: undefined,
  EXPORT_PLAYLIST_REQUEST: undefined,
  EXPORT_PLAYLIST_CREATED: undefined,
  EXPORT_PLAYLIST_CREATED_FAILURE: undefined,
  EXPORT_PLAYLIST_SUCCESS: undefined,
  EXPORT_PLAYLIST_FAILURE: undefined,
  REMOVE_PLAYLIST_REQUEST: undefined,
  REMOVE_PLAYLIST_SUCCESS: undefined,
  REMOVE_PLAYLIST_FAILURE: undefined,
  REMOVE_FROM_PLAYLIST_REQUEST: undefined,
  REMOVE_FROM_PLAYLIST_SUCCESS: undefined,
  REMOVE_FROM_PLAYLIST_FAILURE: undefined,
  REMOVE_ALL_PLAYLISTS_REQUEST: undefined,
  REMOVE_ALL_PLAYLISTS_SUCCESS: undefined,
  REMOVE_ALL_PLAYLISTS_FAILURE: undefined,
  // PLAYER
  PLAYER_UPDATE: undefined,
  SHOW_ALERT: undefined,
  HIDE_ALERT: undefined,
});
// tslint:enable

export const STATUS = {
  ERROR: 'error',
  IDLE: 'idle',
  READY: 'ready',
  RUNNING: 'running',
  SUCCESS: 'success',
};
