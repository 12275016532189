import { IObject } from 'types/common';
import { IUserState } from 'types/state';

import { handleActions, spread } from 'modules/helpers';

import { ActionTypes, REHYDRATE, STATUS } from 'constants/index';

export const userState: IUserState = {
  auth: {
    access_token: '',
    code: '',
    expires_at: 0,
    refresh_token: '',
    state: undefined,
  },
  data: {},
  getData: {
    error: '',
    status: STATUS.IDLE,
  },
  getToken: {
    error: '',
    status: STATUS.IDLE,
  },
  isLogged: false,
  refreshToken: {
    error: '',
    status: STATUS.IDLE,
  },
};

export default {
  user: handleActions(
    {
      [REHYDRATE]: (draft: IObject, { payload = {} }: IObject): IObject => {
        const { user } = payload;
        let newState = { ...userState };

        /* istanbul ignore else */
        if (user) {
          newState = spread(draft, user);
        }

        newState.getData.status = STATUS.IDLE;
        newState.getToken.status = STATUS.IDLE;
        newState.refreshToken.status = STATUS.IDLE;

        return newState;
      },
      [ActionTypes.USER_LOGIN]: (draft: IObject, { payload }: IObject) => {
        draft.auth = spread(draft.auth, payload);
      },
      // GET TOKEN
      [ActionTypes.FETCH_ACCESS_TOKEN_REQUEST]: (draft: IObject) => {
        draft.getToken.error = '';
        draft.getToken.status = STATUS.RUNNING;
      },
      [ActionTypes.FETCH_ACCESS_TOKEN_SUCCESS]: (draft: IObject, { payload }: IObject) => {
        draft.getToken.status = STATUS.SUCCESS;
        draft.auth = spread(draft.auth, payload);
      },
      [ActionTypes.FETCH_ACCESS_TOKEN_FAILURE]: (draft: IObject, { payload }: IObject) => {
        draft.getToken.error = payload.error;
        draft.getToken.status = STATUS.ERROR;
      },
      // REFRESH TOKEN
      [ActionTypes.REFRESH_ACCESS_TOKEN_REQUEST]: (draft: IObject) => {
        draft.refreshToken.error = '';
        draft.refreshToken.status = STATUS.RUNNING;
      },
      [ActionTypes.REFRESH_ACCESS_TOKEN_SUCCESS]: (draft: IObject, { payload }: IObject) => {
        draft.refreshToken.status = STATUS.SUCCESS;
        draft.auth = spread(draft.auth, payload);
      },
      [ActionTypes.REFRESH_ACCESS_TOKEN_FAILURE]: (draft: IObject, { payload }: IObject) => {
        draft.auth = userState.auth;
        draft.refreshToken.error = payload.error;
        draft.refreshToken.status = STATUS.ERROR;
        draft.isLogged = false;
      },
      // GET DATA
      [ActionTypes.FETCH_USER_DATA_REQUEST]: (draft: IObject) => {
        draft.getData.error = '';
        draft.getData.status = STATUS.RUNNING;
      },
      [ActionTypes.FETCH_USER_DATA_SUCCESS]: (draft: IObject, { payload }: IObject) => {
        draft.getData.status = STATUS.SUCCESS;
        draft.data = payload;
        draft.isLogged = true;
      },
      [ActionTypes.FETCH_USER_DATA_FAILURE]: (draft: IObject, { payload }: IObject) => {
        draft.auth = userState.auth;
        draft.getData.error = payload.error;
        draft.getData.status = STATUS.ERROR;
        draft.isLogged = false;
      },
      [ActionTypes.USER_LOGOUT]: (): IObject => {
        return userState;
      },
    },
    userState,
  ),
};
