import { IObject } from 'types/common';
import { all, put, call, takeLatest } from 'redux-saga/effects';

import { getTimestamp } from 'modules/helpers';
import {
  addToSavedTracks,
  getTopTracks,
  getTopArtists,
  getSavedTracks,
  getAudioFeatures,
  getTracksStatus,
  removeFromMySavedTracks,
} from 'modules/spotify';

import { ActionTypes } from 'constants/index';

// tslint:disable:object-literal-sort-keys

function* fetchAudioFeatures({ payload }: IObject) {
  try {
    const data = yield call(getAudioFeatures, payload);

    yield put({
      type: ActionTypes.FETCH_AUDIO_FEATURES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.FETCH_AUDIO_FEATURES_FAILURE,
      payload: {
        id: payload,
        error,
      },
    });
  }
}

function* fetchSavedTracks({ payload }: IObject) {
  try {
    const data = yield call(getSavedTracks, payload);

    yield put({
      type: ActionTypes.FETCH_SAVED_TRACKS_SUCCESS,
      payload: data,
      meta: {
        expires_at: getTimestamp(),
      },
    });
  } catch (err) {
    yield put({
      type: ActionTypes.FETCH_SAVED_TRACKS_FAILURE,
      payload: err,
    });
  }
}

function* fetchTopTracks() {
  try {
    const data = yield call(getTopTracks);

    yield put({
      type: ActionTypes.FETCH_TOP_TRACKS_SUCCESS,
      payload: data,
      meta: {
        expires_at: getTimestamp(),
      },
    });
  } catch (err) {
    yield put({
      type: ActionTypes.FETCH_TOP_TRACKS_FAILURE,
      payload: err,
    });
  }
}

function* fetchTopArtists() {
  try {
    const data = yield call(getTopArtists);

    yield put({
      type: ActionTypes.FETCH_TOP_ARTISTS_SUCCESS,
      payload: data,
      meta: {
        expires_at: getTimestamp(),
      },
    });
  } catch (err) {
    yield put({
      type: ActionTypes.FETCH_TOP_ARTISTS_FAILURE,
      payload: err,
    });
  }
}

function* fetchTracksStatus({ payload }: IObject) {
  try {
    const max = 50;
    const data: string[] = [];

    if (payload.length > max) {
      const batches = [...new Array(Math.ceil(payload.length / max))];

      for (let i = 0; i < batches.length; i++) {
        const start = i * max;
        const tracks = payload.slice(start, 50 * (i + 1));

        const batch = yield call(getTracksStatus, tracks);

        tracks.forEach((d: string, idx: number) => {
          if (batch.body[idx]) {
            data.push(d);
          }
        });
      }
    } else {
      const batch = yield call(getTracksStatus, payload);

      payload.forEach((d: string, idx: number) => {
        if (batch.body[idx]) {
          data.push(d);
        }
      });
    }

    yield put({
      type: ActionTypes.FETCH_TRACKS_STATUS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: ActionTypes.FETCH_TRACKS_STATUS_FAILURE,
      payload: err,
    });
  }
}

function* removeFromFavorites({ payload }: IObject) {
  try {
    const data = yield call(removeFromMySavedTracks, payload);

    yield put({
      type: ActionTypes.REMOVE_TRACK_SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: ActionTypes.REMOVE_TRACK_FAILURE,
      payload: err,
    });
  }
}

function* saveToFavorites({ payload }: IObject) {
  try {
    const data = yield call(addToSavedTracks, payload);

    yield put({
      type: ActionTypes.SAVE_TRACK_SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: ActionTypes.SAVE_TRACK_FAILURE,
      payload: err,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.FETCH_AUDIO_FEATURES_REQUEST, fetchAudioFeatures),
    takeLatest(ActionTypes.FETCH_SAVED_TRACKS_REQUEST, fetchSavedTracks),
    takeLatest(ActionTypes.FETCH_TOP_ARTISTS_REQUEST, fetchTopArtists),
    takeLatest(ActionTypes.FETCH_TOP_TRACKS_REQUEST, fetchTopTracks),
    takeLatest(ActionTypes.FETCH_TRACKS_STATUS_REQUEST, fetchTracksStatus),
    takeLatest(ActionTypes.REMOVE_TRACK_REQUEST, removeFromFavorites),
    takeLatest(ActionTypes.SAVE_TRACK_REQUEST, saveToFavorites),
  ]);
}
