import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hot } from 'react-hot-loader/root';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'simple-react-router-redux';
import Loadable from 'react-loadable';
import cx from 'classnames';

import { setTokens } from 'modules/spotify';
import history from 'store/custom-history';

import Footer from 'components/Footer';
import Header from 'components/Header';
import Player from 'containers/Player';
import Loader from 'components/Loader';
import RouteProtected from 'components/RouteProtected';
import RoutePublic from 'components/RoutePublic';
import ScrollRestoration from 'components/ScrollRestoration';
import SystemNotifications from 'components/SystemNotifications';

import Callback from 'routes/Callback';
import NotFound from 'routes/NotFound';

const AsyncAbout = Loadable({
  loader: () => import(/* webpackChunkName: "about" */ 'routes/About'),
  loading: Loader,
});
const AsyncHome = Loadable({
  loader: () => import(/* webpackChunkName: "home" */ 'routes/Home'),
  loading: Loader,
});
const AsyncGenerator = Loadable({
  loader: () => import(/* webpackChunkName: "generator" */ 'routes/Generator'),
  loading: Loader,
});
const AsyncLibrary = Loadable({
  loader: () => import(/* webpackChunkName: "library" */ 'routes/Library'),
  loading: Loader,
});
const AsyncPlaylist = Loadable({
  loader: () => import(/* webpackChunkName: "playlist" */ 'routes/Playlist'),
  loading: Loader,
});
const AsyncPlaylists = Loadable({
  loader: () => import(/* webpackChunkName: "playlists" */ 'routes/Playlists'),
  loading: Loader,
});
const AsyncPrivacy = Loadable({
  loader: () => import(/* webpackChunkName: "privacy" */ 'routes/Privacy'),
  loading: Loader,
});

export class App extends React.Component {
  constructor(props) {
    super(props);

    setTokens(props.user.auth);
  }

  static propTypes = {
    app: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    hasPlayer: PropTypes.bool.isRequired,
    router: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
  };

  render() {
    const { app, dispatch, hasPlayer, router, user } = this.props;
    const showNavigation = !['/', '/callback'].includes(router.location.pathname);

    return (
      <ConnectedRouter history={history}>
        <div
          className={cx('app', {
            'app--private': user.isLogged,
            'app--pages': showNavigation,
            'app--with-player': hasPlayer,
          })}
        >
          <ScrollRestoration />
          {showNavigation && <Header dispatch={dispatch} location={router.location} user={user} />}
          <main className="app__main">
            <Switch>
              <RoutePublic exact path="/" component={AsyncHome} isAuthenticated={user.isLogged} />
              <RouteProtected
                path="/generator"
                isAuthenticated={user.isLogged}
                component={AsyncGenerator}
              />
              <RouteProtected
                path="/library"
                isAuthenticated={user.isLogged}
                component={AsyncLibrary}
              />
              <RouteProtected
                exact
                path="/playlists"
                isAuthenticated={user.isLogged}
                component={AsyncPlaylists}
              />
              <RouteProtected
                path="/playlists/:uuid"
                isAuthenticated={user.isLogged}
                component={AsyncPlaylist}
              />
              <Route path="/about" component={AsyncAbout} />
              <Route path="/privacy" component={AsyncPrivacy} />
              <Route
                path="/callback"
                render={({ location }) => {
                  return <Callback dispatch={dispatch} location={location} />;
                }}
              />
              <Route component={NotFound} />
            </Switch>
          </main>
          {showNavigation && <Footer />}
          {user.isLogged && <Player />}
          <SystemNotifications notifications={app.notifications} dispatch={dispatch} />
        </div>
      </ConnectedRouter>
    );
  }
}

export default hot(
  connect(state => ({
    app: state.app,
    router: state.router,
    hasPlayer: !!state.player.uris.length,
    user: state.user,
  }))(App),
);
