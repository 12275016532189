import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { hideAlert } from 'actions';

import Icon from 'components/Icon';

let hideTimeout;

const SystemNotifications = ({ dispatch, notifications }) => {
  const timeout = useRef();

  const hideNotification = useCallback(() => {
    dispatch(hideAlert());
  }, [dispatch]);

  const handleClick = useCallback(
    e => {
      e.preventDefault();

      clearTimeout(hideTimeout);
      hideNotification();
    },
    [hideNotification],
  );

  useEffect(() => {
    if (notifications.visible && notifications.withTimeout) {
      clearTimeout(timeout.current);

      timeout.current = setTimeout(() => {
        hideNotification();
      }, 3500);
    }
  });

  const classes = `system-notifications${notifications.visible ? ' active' : ''}${
    notifications.type ? ` ${notifications.type}` : ''
  }`;
  const iconClass = {
    success: 'thumbs-up',
    warning: 'exclamation-circle',
    info: 'info-circle',
    error: 'thumbs-down',
  };

  return (
    <a
      href="#close"
      aria-label="Close"
      key="SystemNotification"
      className={classes}
      onClick={handleClick}
    >
      <div>
        <Icon name={iconClass[notifications.type]} />
        <div>{notifications.message}</div>
      </div>
    </a>
  );
};

SystemNotifications.propTypes = {
  dispatch: PropTypes.func.isRequired,
  notifications: PropTypes.object.isRequired,
};

export default SystemNotifications;
