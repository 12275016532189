import * as React from 'react';

type names =
  | 'album'
  | 'albums'
  | 'anchor'
  | 'angle-double-down'
  | 'angle-double-left'
  | 'angle-double-right'
  | 'angle-double-up'
  | 'angle-down'
  | 'angle-left'
  | 'angle-right'
  | 'angle-up'
  | 'archive'
  | 'area-chart'
  | 'arrow-circle-down'
  | 'arrow-circle-left'
  | 'arrow-circle-o-down'
  | 'arrow-circle-o-left'
  | 'arrow-circle-o-right'
  | 'arrow-circle-o-up'
  | 'arrow-circle-right'
  | 'arrow-circle-up'
  | 'arrow-down'
  | 'arrow-left'
  | 'arrow-right'
  | 'arrow-up'
  | 'arrows'
  | 'arrows-alt'
  | 'arrows-h'
  | 'arrows-v'
  | 'artists'
  | 'asterisk'
  | 'at'
  | 'automobile'
  | 'car'
  | 'backward'
  | 'ban'
  | 'bar-chart'
  | 'bar-chart-o'
  | 'bars'
  | 'navicon'
  | 'reorder'
  | 'bell'
  | 'bell-o'
  | 'bell-slash'
  | 'bell-slash-o'
  | 'bicycle'
  | 'binoculars'
  | 'bolt'
  | 'flash'
  | 'bomb'
  | 'book'
  | 'bookmark'
  | 'bookmark-o'
  | 'broken-note'
  | 'bug'
  | 'building'
  | 'bullhorn'
  | 'bullseye'
  | 'bus'
  | 'cab'
  | 'taxi'
  | 'calendar'
  | 'calendar-check-o'
  | 'calendar-minus-o'
  | 'calendar-o'
  | 'calendar-plus-o'
  | 'calendar-times-o'
  | 'camera'
  | 'camera-retro'
  | 'caret-down'
  | 'caret-left'
  | 'caret-right'
  | 'caret-up'
  | 'cart-arrow-down'
  | 'cart-plus'
  | 'certificate'
  | 'chain'
  | 'link'
  | 'chain-broken'
  | 'unlink'
  | 'check'
  | 'check-circle'
  | 'check-circle-o'
  | 'check-square'
  | 'check-square-o'
  | 'chevron-circle-down'
  | 'chevron-circle-left'
  | 'chevron-circle-right'
  | 'chevron-circle-up'
  | 'chevron-down'
  | 'chevron-left'
  | 'chevron-right'
  | 'chevron-up'
  | 'child'
  | 'circle-o'
  | 'circle-o-notch'
  | 'clock-o'
  | 'close'
  | 'remove'
  | 'times'
  | 'cloud-download'
  | 'cloud-upload'
  | 'code'
  | 'code-fork'
  | 'coffee'
  | 'cog'
  | 'gear'
  | 'cogs'
  | 'gears'
  | 'columns'
  | 'comment'
  | 'comment-o'
  | 'commenting'
  | 'commenting-o'
  | 'comments'
  | 'copyleft'
  | 'copyright'
  | 'creative-commons'
  | 'credit-card'
  | 'credit-card-alt'
  | 'crosshairs'
  | 'cutlery'
  | 'dashboard'
  | 'tachometer'
  | 'desktop'
  | 'dot-circle-o'
  | 'download'
  | 'edit'
  | 'pencil-square-o'
  | 'eject'
  | 'ellipsis-h'
  | 'ellipsis-v'
  | 'envelope'
  | 'envelope-o'
  | 'eraser'
  | 'exchange'
  | 'exclamation-circle'
  | 'exclamation-triangle'
  | 'warning'
  | 'expeditedssl'
  | 'external-link'
  | 'external-link-square'
  | 'eye'
  | 'eye-slash'
  | 'eyedropper'
  | 'facebook'
  | 'facebook-f'
  | 'facebook-square'
  | 'fast-backward'
  | 'fast-forward'
  | 'fax'
  | 'feed'
  | 'rss'
  | 'female'
  | 'file'
  | 'file-o'
  | 'film'
  | 'filter'
  | 'fire'
  | 'fire-extinguisher'
  | 'flag'
  | 'flag-checkered'
  | 'flag-o'
  | 'folder'
  | 'folder-o'
  | 'folder-open'
  | 'folder-open-o'
  | 'forward'
  | 'frown-o'
  | 'gavel'
  | 'legal'
  | 'gift'
  | 'github'
  | 'glass'
  | 'globe'
  | 'google'
  | 'google-plus'
  | 'google-plus-circle'
  | 'google-plus-official'
  | 'group'
  | 'users'
  | 'hand-grab-o'
  | 'hand-rock-o'
  | 'hand-o-right'
  | 'hand-paper-o'
  | 'hand-stop-o'
  | 'hashtag'
  | 'hdd-o'
  | 'headphones'
  | 'heart'
  | 'heart-o'
  | 'history'
  | 'home'
  | 'hourglass'
  | 'hourglass-1'
  | 'hourglass-start'
  | 'hourglass-2'
  | 'hourglass-half'
  | 'hourglass-3'
  | 'hourglass-end'
  | 'hourglass-o'
  | 'image'
  | 'photo'
  | 'picture-o'
  | 'inbox'
  | 'industry'
  | 'info-circle'
  | 'instagram'
  | 'key'
  | 'keyboard-o'
  | 'language'
  | 'laptop'
  | 'library'
  | 'lightbulb-o'
  | 'line-chart'
  | 'linkedin'
  | 'linkedin-square'
  | 'list-alt'
  | 'location-arrow'
  | 'lock'
  | 'long-arrow-down'
  | 'long-arrow-left'
  | 'long-arrow-right'
  | 'long-arrow-up'
  | 'low-vision'
  | 'magic'
  | 'magnet'
  | 'mail-forward'
  | 'share'
  | 'mail-reply'
  | 'reply'
  | 'mail-reply-all'
  | 'reply-all'
  | 'male'
  | 'map'
  | 'map-marker'
  | 'map-o'
  | 'map-pin'
  | 'map-signs'
  | 'meh-o'
  | 'messenger'
  | 'microphone'
  | 'microphone-slash'
  | 'minus'
  | 'minus-circle'
  | 'minus-square'
  | 'minus-square-o'
  | 'mixcloud'
  | 'mobile'
  | 'mobile-phone'
  | 'money'
  | 'moon-o'
  | 'music'
  | 'offline'
  | 'paint-brush'
  | 'paper-plane'
  | 'send'
  | 'pause'
  | 'pause-circle'
  | 'pause-circle-o'
  | 'paw'
  | 'pencil'
  | 'pencil-square'
  | 'percent'
  | 'phone'
  | 'phone-square'
  | 'pie-chart'
  | 'pinterest'
  | 'pinterest-p'
  | 'plane'
  | 'play'
  | 'play-circle'
  | 'play-circle-o'
  | 'playlist'
  | 'plug'
  | 'plus'
  | 'plus-circle'
  | 'plus-square'
  | 'power-off'
  | 'print'
  | 'puzzle-piece'
  | 'question-circle'
  | 'question-circle-o'
  | 'queue'
  | 'quote-left'
  | 'quote-right'
  | 'radio'
  | 'random'
  | 'recycle'
  | 'refresh'
  | 'registered'
  | 'repeat'
  | 'rotate-right'
  | 'return'
  | 'retweet'
  | 'rocket'
  | 'rotate-left'
  | 'undo'
  | 'rss-square'
  | 'search'
  | 'search-minus'
  | 'search-plus'
  | 'share-alt'
  | 'share-square'
  | 'share-square-o'
  | 'shield'
  | 'shopping-bag'
  | 'shopping-basket'
  | 'shopping-cart'
  | 'sign-in'
  | 'sign-out'
  | 'signal'
  | 'sitemap'
  | 'slack'
  | 'sliders'
  | 'smile-o'
  | 'snapchat-ghost'
  | 'songs'
  | 'sort'
  | 'unsorted'
  | 'sort-amount-asc'
  | 'sort-amount-desc'
  | 'sort-asc'
  | 'sort-up'
  | 'sort-desc'
  | 'sort-down'
  | 'soundcloud'
  | 'spinner'
  | 'spotify'
  | 'square'
  | 'square-o'
  | 'star'
  | 'star-half'
  | 'star-half-empty'
  | 'star-half-full'
  | 'star-half-o'
  | 'star-o'
  | 'step-backward'
  | 'step-forward'
  | 'sticky-note'
  | 'sticky-note-o'
  | 'stop'
  | 'stop-circle'
  | 'stop-circle-o'
  | 'street-view'
  | 'suitcase'
  | 'sun-o'
  | 'tablet'
  | 'tag'
  | 'tags'
  | 'television'
  | 'tv'
  | 'th'
  | 'th-large'
  | 'th-list'
  | 'thumb-tack'
  | 'thumbs-down'
  | 'thumbs-o-down'
  | 'thumbs-o-up'
  | 'thumbs-up'
  | 'ticket'
  | 'times-circle'
  | 'times-circle-o'
  | 'toggle-off'
  | 'toggle-on'
  | 'trash'
  | 'trash-o'
  | 'trophy'
  | 'truck'
  | 'tumblr'
  | 'twitter'
  | 'twitter-square'
  | 'umbrella'
  | 'universal-access'
  | 'unlock'
  | 'unlock-alt'
  | 'upload'
  | 'usb'
  | 'user'
  | 'user-secret'
  | 'video-camera'
  | 'vimeo'
  | 'vimeo-square'
  | 'volume-down'
  | 'volume-middle'
  | 'volume-off'
  | 'volume-up'
  | 'whatsapp'
  | 'wheelchair'
  | 'wifi'
  | 'wordpress'
  | 'wpforms'
  | 'wrench'
  | 'youtube'
  | 'youtube-play'
  | 'youtube-square';

interface Props {
  fade?: boolean;
  name: names;
  spin?: boolean;
}

const Icon = ({ fade, name, spin }: Props) => {
  const classes = [`i-${name}`];

  if (fade) {
    classes.push('i-fade');
  }

  if (spin) {
    classes.push('i-spin');
  }

  return <i className={classes.join(' ')} />;
};

export default Icon;
