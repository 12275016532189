import * as React from 'react';
import { Link } from 'react-router-dom';
import { event } from 'modules/analytics';

import Icon from 'components/Icon';

const Footer = () => {
  const handleClick = React.useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    event('global', `share-${e.currentTarget!.className}`);

    window.open(e.currentTarget!.href, 'Social Share', 'width=600,height=400,resizable=no');
  }, []);

  return (
    <footer className="app__footer">
      <div className="app__container">
        <h5>
          <Link to="/about">About</Link>
        </h5>
        <div className="app__footer__share">
          <h5>Share</h5>
          <a
            href="https://www.facebook.com/dialog/share?app_id=404732580142846&display=popup&href=https://musicbot.io&hashtag=#spotify"
            rel="noopener nofollow"
            className="facebook"
            onClick={handleClick}
          >
            <Icon name="facebook-f" />
          </a>
          <a
            href="https://twitter.com/intent/tweet?text=Create awesome playlists with Spotify!&url=https://musicbot.io&hashtags=spotify"
            rel="noopener nofollow"
            className="twitter"
            onClick={handleClick}
          >
            <Icon name="twitter" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
