/**
 * API helper functions.
 */
import { IObject } from 'types/common';
import { apiUrl } from 'config';

interface IActionType {
  endpoint?: string;
  headers?: IObject;
  method?: string;
  payload?: IObject;
  url?: string;
}

interface IParams {
  body?: string;
  headers: IObject;
  method: string;
}

/**
 * Parse request error.
 */
export function parseResponseError(msg: string = ''): string {
  if (!msg || msg.includes('ajax error')) {
    return 'Algum erro ocorreu. Por favor, verifique sua conexão ou tente novamente.';
  }

  return msg;
}

/**
 * Fetch data
 */
export function request(action: IActionType): Promise<any> {
  if (!action) {
    throw new Error('The action parameter is required');
  }

  const errors = [];

  if (!action.endpoint && !action.url) {
    errors.push('`url` or `endpoint`');
  }

  if (!action.payload && action.method === 'PUT') {
    errors.push('`payload`');
  }

  if (errors.length) {
    throw new Error(`Error! You must pass ${errors.join(', ')}`);
  }

  const url = action.url || `${apiUrl}${action.endpoint || ''}`;
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...action.headers,
  };

  const params: IParams = {
    body: undefined,
    headers,
    method: action.method || 'GET',
  };

  if (params.method !== 'GET' && action.payload) {
    params.body = JSON.stringify(action.payload);
  }

  return fetch(url, params).then(
    (response: IObject): Promise<any> => {
      if (response.status > 299) {
        const error: IObject = new Error(response.statusText || response.status);

        error.status = response.status;
        throw error;
      }

      const contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        return response.json();
      }

      return response.text();
    },
  );
}
