import { IObject } from 'types/common';
import { createActions } from 'redux-actions';
import uuid from 'uuid';

import { ActionTypes } from 'constants/index';

export const {
  exportPlaylistRequest: exportPlaylist,
  fetchPlaylistsRequest: fetchPlaylists,
  generatePlaylistRequest: generatePlaylist,
  removeFromPlaylistRequest: removeFromPlaylist,
  removePlaylistRequest: removePlaylist,
  savePlaylistRequest: savePlaylist,
  updatePlaylistRequest: updatePlaylist,
} = createActions({
  [ActionTypes.EXPORT_PLAYLIST_REQUEST]: (id: string): string => id,
  [ActionTypes.FETCH_PLAYLISTS_REQUEST]: (): IObject => ({}),
  [ActionTypes.GENERATE_PLAYLIST_REQUEST]: (seeds: IObject, meta: IObject = {}): IObject => {
    if (!seeds) {
      throw new Error('No seeds');
    }

    const seedsNames = [...meta.seed_tracks, ...meta.seed_artists, ...meta.seed_genres].map(
      (d: IObject): string => d.name,
    );

    return {
      name: `Based on ${seedsNames[0]}${
        seedsNames.length > 1
          ? ` — and ${seedsNames.length - 1} other seed${seedsNames.length > 2 ? 's' : ''}`
          : ''
      }`,
      options: { ...seeds, ...meta },
      seeds,
      uuid: uuid.v1(),
    };
  },
  [ActionTypes.REMOVE_FROM_PLAYLIST_REQUEST]: (playlistId: string, spotifyId: string): IObject => ({
    playlistId,
    spotifyId,
  }),
  [ActionTypes.REMOVE_PLAYLIST_REQUEST]: (id: string): string => id,
  [ActionTypes.SAVE_PLAYLIST_REQUEST]: (playlist: IObject): IObject => playlist,
  [ActionTypes.UPDATE_PLAYLIST_REQUEST]: (id: string, data: IObject): IObject => ({
    data,
    id,
  }),
});
