import { IObject } from 'types/common';
import { IPlaylistsState } from 'types/state';

import { handleActions, spread } from 'modules/helpers';
import { parseRecommendations } from 'modules/spotify';

import { ActionTypes, REHYDRATE, STATUS } from 'constants/index';

export const playlistsState: IPlaylistsState = {
  data: [],
  export: {
    error: '',
    id: undefined,
    status: STATUS.IDLE,
  },
  fetch: {
    error: '',
    status: STATUS.IDLE,
  },
  generate: {
    error: '',
    name: '',
    options: {},
    status: STATUS.IDLE,
    uuid: '',
  },
  removePlaylist: {
    error: '',
    status: STATUS.IDLE,
  },
  removeTrack: {
    error: '',
    status: STATUS.IDLE,
  },
  save: {
    error: '',
    status: STATUS.IDLE,
  },
  update: {
    error: '',
    status: STATUS.IDLE,
  },
};

export default {
  playlists: handleActions(
    {
      [REHYDRATE]: (draft: IObject) => {
        draft.export.status = STATUS.IDLE;
        draft.fetch.status = STATUS.IDLE;
        draft.generate.status = STATUS.IDLE;
        draft.removePlaylist.status = STATUS.IDLE;
        draft.removeTrack.status = STATUS.IDLE;
        draft.save.status = STATUS.IDLE;
        draft.update.status = STATUS.IDLE;
      },
      // EXPORT_PLAYLIST
      [ActionTypes.EXPORT_PLAYLIST_REQUEST]: (draft: IObject) => {
        draft.export.error = '';
        draft.export.status = STATUS.RUNNING;
      },
      [ActionTypes.EXPORT_PLAYLIST_SUCCESS]: (draft: IObject, { payload }: IObject) => {
        draft.data = draft.data.map(
          (d: IObject): IObject => (d.uuid === payload.uuid ? { ...d, exported: true } : d),
        );
        draft.export.status = STATUS.SUCCESS;
      },
      [ActionTypes.EXPORT_PLAYLIST_FAILURE]: (draft: IObject, { payload }: IObject) => {
        draft.export.error = payload;
        draft.export.status = STATUS.ERROR;
      },
      [ActionTypes.EXPORT_PLAYLIST_CREATED]: (draft: IObject, { payload }: IObject) => {
        draft.data = draft.data.map(
          (d: IObject): IObject =>
            d.uuid === payload.id
              ? {
                  ...d,
                  spotify_id: payload.body.id,
                  url: payload.body.external_urls.spotify,
                }
              : d,
        );
      },
      [ActionTypes.EXPORT_PLAYLIST_CREATED_FAILURE]: (draft: IObject, { payload }: IObject) => {
        draft.export.error = payload;
        draft.export.status = STATUS.ERROR;
      },
      // FETCH_PLAYLISTS
      [ActionTypes.FETCH_PLAYLISTS_REQUEST]: (draft: IObject) => {
        draft.fetch.error = '';
        draft.fetch.status = STATUS.RUNNING;
      },
      [ActionTypes.FETCH_PLAYLISTS_SUCCESS]: (draft: IObject, { payload }: IObject) => {
        draft.data = payload;

        draft.fetch.status = STATUS.SUCCESS;
      },
      [ActionTypes.FETCH_PLAYLISTS_FAILURE]: (draft: IObject, { payload }: IObject) => {
        draft.fetch.error = payload;
        draft.fetch.status = STATUS.ERROR;
      },
      // GENERATOR
      [ActionTypes.GENERATE_PLAYLIST_REQUEST]: (draft: IObject, { payload }: IObject) => {
        const { name, options, uuid } = payload;

        draft.generate = spread(draft.generate, {
          error: '',
          name,
          options,
          status: STATUS.RUNNING,
          uuid,
        });
      },
      [ActionTypes.GENERATE_PLAYLIST_SUCCESS]: (draft: IObject, { payload }: IObject) => {
        const { name, options, uuid } = draft.generate;
        const newPlaylist = {
          exported: false,
          name,
          options,
          spotify_id: undefined,
          url: undefined,
          uuid,
          ...parseRecommendations(payload),
        };

        draft.generate.status = STATUS.SUCCESS;
        draft.data.push(newPlaylist);
      },
      [ActionTypes.GENERATE_PLAYLIST_FAILURE]: (draft: IObject, { payload }: IObject) => {
        draft.generate.error = payload;
        draft.generate.status = STATUS.ERROR;
      },
      // SAVE_PLAYLIST
      [ActionTypes.SAVE_PLAYLIST_REQUEST]: (draft: IObject) => {
        draft.save.error = '';
        draft.save.status = STATUS.RUNNING;
      },
      [ActionTypes.SAVE_PLAYLIST_SUCCESS]: (draft: IObject, { payload }: IObject) => {
        draft.data = draft.data.map(
          (d: IObject): IObject => (d.uuid === payload.uuid ? { ...d, ...payload } : d),
        );
        draft.save.status = STATUS.SUCCESS;
      },
      [ActionTypes.SAVE_PLAYLIST_FAILURE]: (draft: IObject, { payload }: IObject) => {
        draft.save.error = payload;
        draft.save.status = STATUS.ERROR;
      },
      // REMOVE_PLAYLIST
      [ActionTypes.REMOVE_PLAYLIST_REQUEST]: (draft: IObject) => {
        draft.removePlaylist.error = '';
        draft.save.status = STATUS.RUNNING;
      },
      [ActionTypes.REMOVE_PLAYLIST_SUCCESS]: (draft: IObject, { payload }: IObject) => {
        draft.data = draft.data.filter((d: IObject): boolean => d.uuid !== payload.id);
        draft.removePlaylist.status = STATUS.SUCCESS;
      },
      [ActionTypes.REMOVE_PLAYLIST_FAILURE]: (draft: IObject, { payload }: IObject) => {
        draft.removePlaylist.error = payload;
        draft.removePlaylist.status = STATUS.ERROR;
      },
      // REMOVE_FROM_PLAYLIST
      [ActionTypes.REMOVE_FROM_PLAYLIST_REQUEST]: (draft: IObject) => {
        draft.removeTrack.error = '';
        draft.removeTrack.status = STATUS.RUNNING;
      },
      [ActionTypes.REMOVE_FROM_PLAYLIST_SUCCESS]: (draft: IObject, { payload }: IObject) => {
        draft.data = draft.data.map(
          (d: IObject): IObject => {
            if (d.uuid === payload.playlistId) {
              d.tracks = d.tracks.filter(
                (t: IObject): boolean => t.spotify_id !== payload.spotifyId,
              );
            }

            return d;
          },
        );
        draft.removeTrack.status = STATUS.SUCCESS;
      },
      [ActionTypes.REMOVE_FROM_PLAYLIST_FAILURE]: (draft: IObject, { payload }: IObject) => {
        draft.removeTrack.error = payload;
        draft.removeTrack.status = STATUS.ERROR;
      },
      // UPDATE_PLAYLIST
      [ActionTypes.UPDATE_PLAYLIST_REQUEST]: (draft: IObject) => {
        draft.update.error = '';
        draft.update.status = STATUS.RUNNING;
      },
      [ActionTypes.UPDATE_PLAYLIST_SUCCESS]: (draft: IObject, { payload }: IObject) => {
        draft.data = draft.data.map(
          (d: IObject): IObject => (d.uuid === payload.uuid ? { ...d, ...payload } : d),
        );
        draft.update.status = STATUS.SUCCESS;
      },
      [ActionTypes.UPDATE_PLAYLIST_FAILURE]: (draft: IObject, { payload }: IObject) => {
        draft.update.error = payload;
        draft.update.status = STATUS.ERROR;
      },
      [ActionTypes.USER_LOGOUT]: (): IObject => playlistsState,
    },
    playlistsState,
  ),
};
