import { IObject } from 'types/common';
import { IPlayerState } from 'types/state';
import { handleActions, spread } from 'modules/helpers';

import { ActionTypes } from 'constants/index';

export const playerState: IPlayerState = {
  id: '',
  isPlaying: false,
  offset: undefined,
  uris: [],
};

export default {
  player: handleActions(
    {
      [ActionTypes.PLAYER_UPDATE]: (draft: IObject, { payload }: IObject): IObject =>
        spread(draft, payload),
      [ActionTypes.USER_LOGOUT]: (): IObject => playerState,
    },
    playerState,
  ),
};
